/* questrial-400normal - latin */
@font-face {
  font-family: 'Questrial';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Questrial Regular '),
    local('Questrial-Regular'),
    url('./files/questrial-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/questrial-latin-400.woff') format('woff'); /* Modern Browsers */
}

